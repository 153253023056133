import classie from 'desandro-classie';
import Inputmask from 'inputmask';

function onInputFocus(e) {
  classie.add(e.target, 'active');
}

function onInputBlur(e) {
  if(e.target.value.trim() === '') {
    classie.remove(e.target, 'active');
  }
};

[].slice.call(document.querySelectorAll('.form__field')).forEach((input) => {
  if(input.value.trim() !== '') {
    classie.add(input, 'active');
  }

  input.addEventListener('focus', onInputFocus);
  input.addEventListener('blur', onInputBlur);
});

document.querySelectorAll('.input-email').forEach(field => {
	Inputmask({
		alias: 'email',
    showMaskOnHover: false,
	}).mask(field);
});

document.querySelectorAll('.input-phone').forEach(field => {
	Inputmask({
		mask: '+7 (999) 999-99-99',
    showMaskOnHover: false,
	}).mask(field);
});